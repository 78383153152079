import { CameraIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import { GradientPhoto } from './GradientPhoto';
import { GradientClothing } from './GradientClothing';
import { GradientModel } from './GradientModel';
export default function InputFile2({
  fileselector,
  imageurl,
  loginCheck,
  iconType = 'model'
}) {
  const [isDragging, setIsDragging] = useState(false);
  const { t } = useTranslation('common');

  const availableIcons = {
    model: <GradientModel gender="female" className="h-20" />,
    photo: <GradientPhoto className="h-20" />,
    clothing: <GradientClothing className="h-20" />
  };

  const activeIcon = availableIcons[iconType];

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length) {
      const event = { target: { files } };
      fileselector(event); // Use the existing selectFile function
    }
  };

  return (
    <label
      type="button"
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className="flex flex-col justify-center items-center text-slate-500 w-full h-full border-dashed border-4 rounded-2xl  text-center hover:border-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-neutral-100 "
    >
      {!imageurl && (
        <div className=" text-[#4138C2] p-6">
          {/* <PhotoIcon className="stroke-1.5"></PhotoIcon> */}
          {activeIcon}
        </div>
      )}
      <input
        id="file-upload"
        name="file-upload"
        type="file"
        accept="image/*"
        className="sr-only"
        onClick={loginCheck}
        onChange={fileselector}
      />
      {!imageurl && (
        <div className="leading-none mb-4">
          <span className="mt-1 block text-xs md:text-sm font-medium ">
            {t('Click here, drop your image,')} <br></br>{' '}
            {t('take a photo or ⌘ + v')}
          </span>
        </div>
      )}

      {imageurl && (
        <section className="justify-center items-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  p-1 h-full min-h-full rounded-md ">
          <img
            src={imageurl}
            className="rounded-md shadow-lg object-cover w-full h-full"
          />
          <div>
            {/* <span className="ml-3 sm:ml-4 block text-sm font-medium leading-none">
              <CheckCircleIcon className="text-[#04D63E] w-5 inline"></CheckCircleIcon>
              <span className="ml-1">{t('Image added Successfully')}</span>
            </span>
            <span className="block text-sm font-bold ml-3">
              {t('Click to change')}
            </span> */}
          </div>
        </section>
      )}
    </label>
  );
}
